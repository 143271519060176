import request from '@/utils/request';

export const getVerifyCode = params =>
  request.get('/pipe-api/auth/formula', { params });

export const getLogin = params => request.post('/pipe-api/auth/login', params);

// 获取用户是否进件（状态）
export const getUserState = params =>
  request.get(`/manager-api/user/getUser`, { params });
