<template>
  <div class="loginContainer">
    <div class="language-button-wrapper">
      <LanguageButton />
    </div>
    <div class="left" />
    <div class="right">
      <div>
        <p class="accPass">{{ $t('site_title') }}</p>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="login">
            <el-input
              :placeholder="$t('login_username_placeholder')"
              prefix-icon="el-icon-user"
              v-model.trim="form.login"
              onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :placeholder="$t('login_password_placeholder')"
              prefix-icon="el-icon-lock"
              v-model.trim="form.password"
              type="password"
            />
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              :placeholder="$t('login_code_placeholder')"
              prefix-icon="el-icon-circle-check"
              v-model.trim="form.code"
              class="verifyInput"
              onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
              @keydown.enter.native="handleLogin"
            >
              <img
                :src="verifyUrl"
                alt="verifyCode"
                slot="suffix"
                class="verifyImg"
                @click="this.changeVerifyCode"
              />
            </el-input>
          </el-form-item>
        </el-form>
        <div class="forget">
          <p>
            <span>{{ $t('login_presentation_start') }}</span>
            <span @click="$router.push('/user/register')">
              {{ $t('login_presentation_end') }}
            </span>
          </p>
          <span @click="$router.push('/user/forget-password')">
            {{ $t('login_forgotten_password') }}
          </span>
        </div>
        <el-button
          :loading="loading"
          class="logIn"
          type="primary"
          @click="handleLogin"
        >
          {{ $t('login_button') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getVerifyCode, getLogin, getUserState } from './api';
import { mapMutations } from 'vuex';
import LanguageButton from '@/components/LanguageButton.vue';

export default {
  name: 'login',
  data() {
    return {
      loading: false,
      verifyUrl: '',
      form: {
        login: '',
        password: '',
        code: '',
      },
      rules: {
        login: [
          {
            required: true,
            message: this.$t('login_username_placeholder'),
            trigger: 'change',
          },
          // { type: 'email', message: '请输入正确的邮箱格式', trigger: 'change' },
        ],
        password: [
          {
            required: true,
            message: this.$t('login_password_placeholder'),
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            message: this.$t('login_code_placeholder'),
            trigger: 'change',
          },
        ],
      },
    };
  },
  mounted() {
    this._getVerifyCode();
  },
  methods: {
    ...mapMutations(['setMenus', 'setToken', 'setUsername', 'setCompanyName']),
    async _getVerifyCode() {
      try {
        this.verifyUrl = await getVerifyCode();
      } catch (err) {
        console.error(err);
      }
    },
    changeVerifyCode() {
      this._getVerifyCode();
    },
    async handleLogin() {
      try {
        const valid = await this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          const params = { ...this.form };
          const { menu, token, name } = await getLogin(params);
          this.setMenus(menu); // 存menu
          this.setToken(token); // 存token
          this.setCompanyName(name);
          this.setUsername(params.login);
          // 获取用户状态
          const { commitStatus } = await getUserState();
          this.$message.success(this.$t('login_success'));
          if (commitStatus === 3) {
            this.$router.push('/');
            return;
          }
          if (menu[0].child[0].child?.length === 1) {
            await this.$router.push('/incoming-unit');
          } else {
            await this.$router.push('/incoming-unit');
          }
          this.loading = false;
          // await this.$router.push('/home-main');
        }
      } catch (err) {
        await this.changeVerifyCode();
        this.loading = false;
      }
    },
  },
  components: {
    LanguageButton,
  },
};
</script>

<style scoped lang="scss">
.loginContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  //justify-content: center;
  //align-items: center;

  .language-button-wrapper {
    width: 100px;
    height: 40px;
    position: absolute;
    top: 40px;
    right: 120px;
  }

  .left {
    width: 498px;
    height: 100%;
    background: url('./img/background.png') no-repeat center;
    background-size: cover;
  }

  .right {
    width: calc(100% - 498px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div:first-child {
      width: 450px;
      height: 420px;

      .verifyImg {
        display: inline-block;
        width: 104px;
        height: 37px;
        position: absolute;
        top: 2px;
        left: 10px;
        cursor: pointer;
        border: 1px solid #cccccc;
        border-radius: 4px;
      }

      .forget {
        display: flex;
        justify-content: space-between;
        color: #555555;
        & > p {
          & > span:nth-child(2) {
            color: #147fd2;
            cursor: pointer;
          }
        }
        & > span {
          color: #147fd2;
          cursor: pointer;
        }
        width: 100%;
        font-size: 14px;
        position: relative;
      }

      .logIn {
        width: 100%;
        background: #1890ff;
        border-radius: 8px;
        color: #fff;
        font-size: 24px;
        margin-top: 18px;
      }

      .accPass {
        text-align: center;
        font-size: 32px;
        color: #147fd2;
        margin-bottom: 40px;
        font-weight: 500;
      }
    }
  }
}

::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #2c68ff;
  color: #fff;
}

::v-deep .el-menu-item {
  height: 72px;
  line-height: 72px;
  transition: none !important;
}

::v-deep .el-submenu {
  .el-submenu__title {
    height: 72px;
    line-height: 72px;
  }
}

::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
  border-bottom-color: transparent !important;
}

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}

.verifyInput {
  width: 75%;
}
</style>
